import React, { useContext } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { rtColors } from '@ritualco/ritual-frontend-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  MERCHANT_STATUS,
  getMerchantState,
  getMerchantTodaysHours,
  getMerchantOpen,
  getMerchantUnlisted,
} from '@artemis/store/merchant/selectors';

const Container = styled.div`
  ${({ theme, bg }) =>
    bg === 'error'
      ? `
      background: ${theme.rtColors.red100};
      color: ${theme.rtColors.red700};
      `
      : `
      background: ${theme.rtColors.yellow100};
      color: ${theme.rtColors.yellow700};
      `}
  height: 76px;
  text-align: left;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 4pt;

  margin-left: 16px;
  margin-right: 16px;

  ${({ theme }) => theme.isTablet`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `}
`;
const Status = styled.h2`
  font-size: 16px;
  width: 100%;
  vertical-align: bottom;
`;

const Message = styled.p`
  font-size: 12px;
  width: 100%;
  vertical-align: top;
`;

const Text = styled.div``;

const MerchantStatusBanner = ({ className }) => {
  const { authenticated } = useContext(AuthenticationContext);
  const state = useSelector(getMerchantState);
  const isMerchantUnlisted = useSelector(getMerchantUnlisted);
  const open = useSelector(getMerchantOpen);
  const todaysHours = useSelector(getMerchantTodaysHours);

  let status = '';
  let showHours = false;
  let showErrorIcon = false;
  let color = '';
  let message = '';
  switch (state) {
    case MERCHANT_STATUS.unknown:
    case MERCHANT_STATUS.off:
    case MERCHANT_STATUS.down:
    case MERCHANT_STATUS.downManual:
    case MERCHANT_STATUS.platformIssue:
    case MERCHANT_STATUS.lost:
    case MERCHANT_STATUS.accountIssue:
    case MERCHANT_STATUS.downPrompt:
    case MERCHANT_STATUS.downUntilTomorrow:
    case MERCHANT_STATUS.unresponsivePrompt:
    case MERCHANT_STATUS.unresponsiveUntilTomorrow:
      showErrorIcon = true;
      status = 'merchantState.pausedTitle';
      message = 'merchantState.pausedMessage';
      color = 'warning';
      break;
    case MERCHANT_STATUS.overCapacity:
    case MERCHANT_STATUS.unresponsiveOverCapacity:
      showErrorIcon = true;
      status = 'merchantState.pausedTitle';
      message = 'merchantState.busyMessage';
      color = 'warning';
      break;
    case MERCHANT_STATUS.on:
    case MERCHANT_STATUS.whitelist:
    case MERCHANT_STATUS.debug:
    default:
      break;
  }

  if (!open) {
    showErrorIcon = true;
    status = 'merchantState.closed';
    message = '';
    color = 'error';
    showHours = true;
  }
  if (!authenticated && isMerchantUnlisted) {
    showErrorIcon = true;
    status = 'merchantState.unlistedTitle';
    message = 'merchantState.checkLater';
    color = 'warning';
  }
  return status ? (
    <Container bg={color} className={className}>
      {showErrorIcon && (
        <ErrorOutlineOutlinedIcon
          sx={{
            color: color === 'error' ? rtColors.red700 : rtColors.yellow700,
            float: 'left',
            fontSize: 20,
            padding: '28px',
          }}
        />
      )}
      <Text>
        <Status>
          <FormattedMessage entry={status} />
        </Status>
        <Message>
          {showHours ? todaysHours : <FormattedMessage entry={message} />}
        </Message>
      </Text>
    </Container>
  ) : (
    <div aria-hidden="true" style={{ height: '0px' }}></div>
  );
};

MerchantStatusBanner.propTypes = {
  className: PropTypes.string,
};

export default MerchantStatusBanner;
