import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import InfoSection from '@artemis/components/Cart/InfoSection';
import { PaymentSplitShape } from '@artemis/components/GroupOrder/propTypes';
import { getItemsInCart } from '@artemis/store/cart/selectors';
import { GroupOrderInfoShape } from './propTypes';

const CartHeaderContainer = styled.div`
  margin-bottom: 16px;
`;

const YourOrder = styled.h2`
  ${props => props.theme.typography.h5};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 16px;
`;

const CartHeader = ({
  className,
  isDelivery,
  isDineIn,
  isInstoreOrder,
  isGroupOrder,
  groupOrderInfo,
  orderLaterOnly,
  merchantAddress,
  merchantMapLink,
  deliveryAddress,
  pickupEstimate,
  scheduledForTime,
  paymentSplit,
  currencyCode,
  readOnly,
}) => {
  const numCartItems = useSelector(getItemsInCart);
  const cartItems = numCartItems === 0 ? '' : `(${numCartItems})`;

  return (
    <CartHeaderContainer className={className}>
      <YourOrder>
        <FormattedMessage entry="cart.yourCart" values={{ cartItems }} />
      </YourOrder>
      <InfoSection
        isDelivery={isDelivery}
        isDineIn={isDineIn}
        isInstoreOrder={isInstoreOrder}
        isGroupOrder={isGroupOrder}
        groupOrderInfo={groupOrderInfo}
        orderLaterOnly={orderLaterOnly}
        merchantAddress={merchantAddress}
        merchantMapLink={merchantMapLink}
        deliveryAddress={deliveryAddress}
        pickupEstimate={pickupEstimate}
        scheduledForTime={scheduledForTime}
        paymentSplit={paymentSplit}
        currencyCode={currencyCode}
        readOnly={readOnly}
      />
    </CartHeaderContainer>
  );
};

CartHeader.propTypes = {
  className: PropTypes.string,
  isDelivery: PropTypes.bool,
  isDineIn: PropTypes.bool,
  orderLaterOnly: PropTypes.bool,
  isInstoreOrder: PropTypes.bool,
  isGroupOrder: PropTypes.bool,
  groupOrderInfo: GroupOrderInfoShape,
  merchantAddress: PropTypes.string,
  merchantMapLink: PropTypes.string,
  deliveryAddress: PropTypes.string,
  pickupEstimate: PropTypes.number,
  scheduledForTime: PropTypes.shape({
    date: PropTypes.string,
    isToday: PropTypes.bool,
    isTomorrow: PropTypes.bool,
  }),
  paymentSplit: PaymentSplitShape,
  currencyCode: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default CartHeader;
