import React, { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { openCartItem } from '@artemis/store/menu/slice';
import { removeFromCart, addPromo } from '@artemis/store/cart/slice';
import { closeModal as closeSchedulingModal } from '@artemis/store/scheduling/slice';
import {
  getMerchantStreetAddress,
  getMerchantId,
  getMarketingDataList,
  getMerchantSupportsScheduling,
  getMerchantMapLink,
  getCurrencyCode,
  getMerchantUnlisted,
} from '@artemis/store/merchant/selectors';
import {
  getCartHasItems,
  getCart,
  getCartLoading,
  getCartSubtotal,
  getFullDeliveryAddress,
  getCartPickupFulfillmentEstimate,
  getIsDeliveryCart,
  getCanOrderNow,
  getScheduledForTime,
  getCutleryIncluded,
  getCutlerySupported,
  getCartUpdating,
} from '@artemis/store/cart/selectors';
import { getIsSchedulingModalOpen } from '@artemis/store/scheduling/selectors';
import {
  getActiveCurrencyCredits,
  hasActiveCurrencyCredits,
} from '@artemis/store/user/selectors';
import Loading from '@artemis/components/Loading';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import ItemList from '@artemis/components/Cart/ItemList';
import CartHeader from '@artemis/components/Cart/CartHeader';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import Cutlery from '@artemis/components/Cutlery';
import {
  getFulfillmentPaymentSplit,
  getGroupDisplay,
  getIsCurrentUserAdmin,
  getIsGroupOrder,
} from '@artemis/store/groupOrder/selectors';
import MerchantStateAlert from '@artemis/containers/Menu/MerchantStateAlert';
import {
  CLAIM_PARAM,
  DINE_IN_DESCRIPTION_PARAM,
  PICKUP_PARAM,
} from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import EmptyCart from './EmptyCart';
import DeliveryMessage from './DeliveryMessage';
import useHandleCheckoutClick from './useHandleCheckoutClick';
import useIsOrderLaterOnly from './useIsOrderLaterOnly';
import useIsDineIn from './useIsDineIn';

const SchedulingModal = dynamic(() =>
  import('@artemis/containers/Menu/SchedulingModal'),
);

const containerPadding = '24px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  ${({ theme }) => theme.noScrollbars};
  flex-grow: 1;
  background: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${props => props.theme.isTablet`
    border: 1px solid ${props.theme.palette.grey[300]};
    display: flex;
    flex-direction: column;
  `}
`;

const StyledCartHeader = styled(CartHeader)`
  padding: 0 ${containerPadding};
  ${props => props.theme.isTablet`
    padding: ${containerPadding} ${containerPadding} 0;
  `}
`;

const StyledEmptyCart = styled(EmptyCart)`
  margin-bottom: ${containerPadding};
`;

const StyledCutlery = styled(Cutlery)`
  padding: 0 ${containerPadding};
`;

const Button = styled.button`
  flex: 0 0 48px;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  margin: 0 16px 40px;
  padding: 0 16px;
  background-color: ${props =>
    props.disabled
      ? props.theme.palette.grey[100]
      : props.theme.palette.primary};
  color: ${props =>
    props.disabled
      ? props.theme.palette.grey[400]
      : props.theme.palette.common.white};
  ${props => props.theme.typography.button};
  transition: 0.25s background-color ease;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  ${props => props.disabled && 'cursor: auto;'};
  ${props => props.theme.typography.h6};
  span {
    ${props => props.theme.typography.body};
  }
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.palette.grey[100]
        : props.theme.palette.hover.onLightBg(props.theme.palette.primary)};
  }

  ${props => props.theme.isTablet`
    flex: 0 0 50px;
    margin: 0 ${containerPadding} ${containerPadding};
  `}
`;

const ScrollContainer = styled.div`
  ${({ theme }) => theme.scrollShadows.verticalLinear}
  flex-grow: 1;
  overflow-y: auto;
  min-height: 100px;
  padding: 0 ${containerPadding};
`;

const CreditsText = styled.p`
  ${({ theme }) => theme.typography.bodySmall};
  padding: 0 ${containerPadding};
  text-align: center;
`;

const MerchantState = styled(MerchantStateAlert)`
  margin: 0 ${containerPadding};
  width: auto;
`;

const StyledDeliveryMessage = styled(DeliveryMessage)`
  padding: 0 24px 24px 24px;
`;

const CartContainer = ({
  className,
  overrideMerchantAddress,
  isModal = false,
}) => {
  const dispatch = useDispatch();
  const { authenticated } = useContext(AuthenticationContext);
  const isDineIn = useIsDineIn();

  const merchantId = useSelector(getMerchantId);

  const merchantAddress =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    overrideMerchantAddress || useSelector(getMerchantStreetAddress);
  const hasItems = useSelector(getCartHasItems);
  const loading = useSelector(getCartLoading);
  const updating = useSelector(getCartUpdating);
  const cart = useSelector(getCart);
  const cartSubtotal = useSelector(getCartSubtotal);
  const pickupEstimate = useSelector(getCartPickupFulfillmentEstimate);
  const canOrderNow = useSelector(getCanOrderNow);
  const merchantMapLink = useSelector(getMerchantMapLink);

  const isMerchantUnlisted = useSelector(getMerchantUnlisted);
  const isOrderingDisabled = isMerchantUnlisted && !authenticated;

  const isDelivery = useSelector(getIsDeliveryCart);
  const fullDeliveryAddress = useSelector(getFullDeliveryAddress);

  const isGroupOrderAdmin = useSelector(getIsCurrentUserAdmin);
  const groupDisplay = useSelector(getGroupDisplay);

  const onModifyItem = payload => dispatch(openCartItem(payload));
  const onRemoveFromCart = payload =>
    dispatch(removeFromCart({ merchantId, ...payload }));

  // Set the merchant fulfillment type if this is a dine in order.
  const { query } = useRouter();
  const dineInDescription = query[DINE_IN_DESCRIPTION_PARAM];
  const overrideDineInWithPickup = useQueryCheck(PICKUP_PARAM);
  const marketingCampaignList = useSelector(getMarketingDataList);

  const hasCredits = useSelector(hasActiveCurrencyCredits);
  const userCredits = useSelector(getActiveCurrencyCredits);

  const merchantSupportsScheduling = useSelector(getMerchantSupportsScheduling);
  const currencyCode = useSelector(getCurrencyCode);

  const orderLaterOnlyEnabled = useIsOrderLaterOnly();
  const isGroupOrder = useSelector(getIsGroupOrder);

  const scheduledForTime = useSelector(getScheduledForTime);
  const isSchedulingModalOpen = useSelector(getIsSchedulingModalOpen);

  const includeCutlery = useSelector(getCutleryIncluded);
  const hasCutleryOption = useSelector(getCutlerySupported);

  const paymentSplit = useSelector(getFulfillmentPaymentSplit);

  const { handleCheckoutClick, handleCheckout } = useHandleCheckoutClick();

  useEffect(() => {
    if (
      hasItems &&
      CLAIM_PARAM in query &&
      marketingCampaignList?.length &&
      marketingCampaignList[0]?.discountCode?.discountCode
    ) {
      dispatch(
        addPromo({
          merchantId,
          checkoutCodeToAdd: marketingCampaignList[0].discountCode.discountCode,
        }),
      );
    }
  }, [hasItems, query]);

  return (
    <Container className={className}>
      <Loading isLoading={loading || updating} />
      <StyledCartHeader
        isDelivery={isDelivery}
        isDineIn={isDineIn}
        isInstoreOrder={overrideDineInWithPickup}
        isGroupOrder={isGroupOrder}
        groupOrderInfo={{
          isAdmin: isGroupOrderAdmin,
          hostName: groupDisplay?.name,
        }}
        orderLaterOnly={orderLaterOnlyEnabled}
        merchantAddress={merchantAddress}
        merchantMapLink={merchantMapLink}
        deliveryAddress={fullDeliveryAddress}
        pickupEstimate={pickupEstimate}
        scheduledForTime={scheduledForTime}
        paymentSplit={paymentSplit}
        currencyCode={currencyCode}
        readOnly={isGroupOrder}
      />
      {!hasItems ? (
        <ScrollContainer>
          <StyledEmptyCart
            isDineIn={isDineIn}
            isInstoreOrder={overrideDineInWithPickup}
            tableName={dineInDescription}
          />
        </ScrollContainer>
      ) : (
        <>
          <ScrollContainer>
            <ItemList
              items={cart.cartItems}
              onModifyItem={onModifyItem}
              onRemoveFromCart={onRemoveFromCart}
              currencyCode={currencyCode}
            />
          </ScrollContainer>
          {hasCutleryOption && (
            <StyledCutlery
              merchantId={merchantId}
              includeCutlery={includeCutlery}
            />
          )}
          {hasCredits && (
            <CreditsText>
              <FormattedMessage
                entry="cart.credits"
                values={{
                  credits: userCredits,
                  bold: text => <strong>{text}</strong>,
                }}
              />
            </CreditsText>
          )}
          {isModal && !canOrderNow && <MerchantState />}
          <Button
            disabled={!canOrderNow || isOrderingDisabled}
            onClick={e => {
              if (canOrderNow && !isOrderingDisabled) handleCheckoutClick(e);
            }}
          >
            <FormattedMessage entry="cart.checkout" />
            <span>
              <FormattedMessage
                entry="cart.subtotal"
                values={{ value: cartSubtotal }}
              />
            </span>
          </Button>
          <StyledDeliveryMessage />
        </>
      )}
      {merchantSupportsScheduling && (
        <SchedulingModal
          isOpen={isSchedulingModalOpen}
          onRequestClose={() => dispatch(closeSchedulingModal())}
          handleCheckout={handleCheckout}
        />
      )}
    </Container>
  );
};

CartContainer.propTypes = {
  className: PropTypes.string,
  overrideMerchantAddress: PropTypes.string,
  isModal: PropTypes.bool,
};

export default CartContainer;
