import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CustomModal from '@artemis/components/Modal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import Cart from '@artemis/containers/Cart';

const StyledModal = styled(CustomModal)`
  display: flex;
  max-width: 510px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4pt;
`;

const StyledCart = styled(Cart)`
  box-shadow: none;
  margin-top: 60px;
`;

const CloseModal = styled.button`
  background: ${props => props.theme.palette.action.hover};
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin: 15px;
  margin-bottom: 0;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const CloseIcon = styled(ResponsiveImage)`
  height: 24px;
  width: 24px;
`;

const Modal = ({ isOpen, onRequestClose }) => (
  <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
    <CloseModal data-testid="close-menu-item" onClick={onRequestClose}>
      <CloseIcon id="close.svg.img" />
    </CloseModal>
    {isOpen && <StyledCart isModal />}
  </StyledModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default Modal;
